.algo-addict-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color:transparent; /* Adjust according to your theme */
    border-radius: 8px;
    
  }
  
  .algo-addict-text {
    color: #fcfcfc; /* Adjust according to your theme */
    font-size: 12px;
    cursor: pointer;
  }
  .algo-addict-nfd-text{
    color: #fcfcfc; /* Adjust according to your theme */
    font-size: 12px;
    cursor: pointer;
  }
  .algo-addict-text:hover {
    text-decoration: underline;
  }
  