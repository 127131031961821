.roadmap-step {
    border: 1px solid #000000;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .roadmap-step h3 {
    margin-top: 0;
    color: #686868;

  }
  
  .roadmap-step .date {
    font-size: 0.9rem;
    color: #050404;
  }
  
  .roadmap-step.accomplished {
    background-color: #e0ffe0; /* Light green for accomplished steps */
    color: #050404;

  }
  
  .roadmap-step.planned {
    background-color: #ffe0e0; /* Light red for planned steps */
    color: #050404;

  }
  
  