
html,body {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #e40145,#5e005c ); /* Gradient background */

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;

}
.App {
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin: 0 10px;
}

nav a {
  text-decoration: none;
  color: white;
}

nav a:hover {
  text-decoration: underline;
}

