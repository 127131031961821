.swap-component {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .swap-component h2 {
    text-align: center;
  }
  
  .swap-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .swap-inputs input {
    width: 48%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .swap-component button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .swap-component button:hover {
    background-color: #0056b3;
  }
  