.about-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .about-section {
    margin-bottom: 20px;
  }
  
  .about-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .about-subtitle {
    font-size: 1.3em;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .about-text {
    font-size: 1em;
    line-height: 1.6;
    color: #d8d8d8;
    margin-bottom: 10px;
  }
  
  .about-list {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .about-list-item {
    font-size: 1em;
    color: #d8d8d8;
    margin-bottom: 5px;
  }
  