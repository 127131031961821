/* NFTMinter.css */

body {
    font-family: Arial, sans-serif; /* Provides a more readable typeface */
}

.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 500px; /* Ensures the container is not too wide on larger screens */
}

.input, .textarea {
    width: 100%; /* Full width to utilize mobile screen real estate */
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    width: 100%;
    padding: 12px 20px;
    background-color: #4CAF50; /* A pleasant green */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Larger font size for easier interaction on mobile */
}

.button:hover {
    background-color: #45a049; /* Slightly darker green on hover for feedback */
}

/* Responsive adjustments for larger screens can be added here if necessary */
@media (min-width: 768px) {
    .input, .textarea {
        width: 80%; /* Slightly less width for better aesthetics on larger screens */
    }
}

