.wallet-connector {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wallet-button {
    background-color: #4caf50; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wallet-button svg {
    margin-right: 8px; /* Space between the icon and text */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wallet-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .navigate-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .navigate-button:hover {
    background-color: #218838;
  }
  