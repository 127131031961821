.asset-info-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .asset-info-header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .asset-info-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .asset-info-body {
    padding: 10px 0;
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
  
  .label {
    font-weight: bold;
    color: #555;
  }
  
  .value {
    color: #000;
    text-align: right;
  }
  