/* Home Page Styles */
.home-container {
  font-family: Arial, sans-serif;

}

/* Hero Section */
.hero-section {

  color: white;
  padding: 50px 20px;
  text-align: center;
}

.hero-section .hero-content {
  max-width: 600px;
  margin: 0 auto;
  
}

.hero-section h1 {
  font-size: 2.5rem;
}

.hero-section p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.cta-button {
  background-color: #dc0143;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #dc0143;
}

/* Features Section */
.features-section {
  padding: 40px 20px;
  text-align: center;
  color: white;
}

.features-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  max-width: 200px;
  margin: 10px;
  text-align: center;
  
}

.feature .icon {
  font-size: 3rem;
  color: #9C27B0;
  margin-bottom: 10px;
}

/* Roadmap Preview Section */
.roadmap-preview {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  color: black;
}

.roadmap-preview h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.link-button {
  background-color: #FFC107;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #FFA000;
}

/* Community Section */
.community-section {
  padding: 40px 20px;
  text-align: center;
  color: white;
}

.community-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonials {
  max-width: 600px;
  margin: 0 auto;
}

blockquote {
  font-style: italic;
  margin: 20px 0;
}

cite {
  display: block;
  margin-top: 10px;
  font-size: 0.9rem;
  color: white;
}

/* Newsletter Signup Section */
.newsletter-signup {
  background-color: #f3f3f3;
  color: black;
  padding: 40px 20px;
  text-align: center;
}

.newsletter-signup h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.signup-form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.signup-form input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.signup-form button {
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #E91E63;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-form button:hover {
  background-color: #C2185B;
}
