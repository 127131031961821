/* Mobile-first base styles */

.App {
  text-align: center;
  color: white;
}



nav a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  padding: 10px;
}

nav a:hover {
  text-decoration: underline;
}

/* Media queries for larger screens */

@media (min-width: 600px) {
  nav ul {
    flex-direction: row; /* Horizontal layout on larger screens */
    justify-content: center;
  }

  nav li {
    margin: 0 10px; /* Space items horizontally */
  }



  
}
