/* Navbar Styles */
.nav {
  padding: 10px 20px;
  position: relative;
}

.hamburger-menu {
  display: block; /* Ensure it is visible by default */
  cursor: pointer;
}

.nav-icon {
  font-size: 1.5rem;
  color: white;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.nav-list.open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-list li {
  margin: 0 15px;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.nav-link .nav-icon {
  margin-right: 8px;
}

.nav-link:hover {
  color: #E91E63; /* Pink on hover */
}

/* Media queries for smaller screens */
@media (max-width: 600px) {
  .nav-list {
    display: none;
    flex-direction: column;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-list li {
    margin: 10px 0;
  }
}

/* Media queries for larger screens */
@media (min-width: 601px) {
  .hamburger-menu {
    display: none;
  }
}

